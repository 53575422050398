<template>
    <div>
      <base-header class="pb-6">
        <b-row class="py-4">
          <b-col lg="6" cols="7">
            <h6 class="h2 text-white d-inline-block mb-0">Rincian Pelaporan Progress</h6>
          </b-col>
          <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
            <base-button @click="back()">Kembali</base-button>
          </b-col>
        </b-row>
      </base-header>
  
      <div class="container-fluid mt--6">
        <div class="row justify-content-center mb--4">
          <div class="col">
            <div id="umum" class="card">
              <div class="card-header d-flex justify-content-between">
                <h3 class="mb-0">Bukti Pemasangan SPARING Terkait COVID-19</h3>
              </div>

              <div v-if="listData.length == 0">
                <h1>Loading ...</h1>
              </div>
              <div v-else class="card-body">
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Nama Perusahaan</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ listData[0].comp_name }}</span>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Jenis Industri</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ listData[0].comp_type_name }}</span>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Alamat Perusahaan</strong>
                  </div>
                  <div class="col-lg-10">
                    <!-- <span>{{ listData[0].company_address }}</span> -->
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Provinsi</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ listData[0].province_name }}</span>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Kabupaten / Kota</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ listData[0].kabkot_name }}</span>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Telepon Kantor</strong>
                  </div>
                  <div class="col-lg-10">
                    <!-- <span>{{ listData[0].company_phone }}</span> -->
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Email Kantor</strong>
                  </div>
                  <div class="col-lg-10">
                    <!-- <span>{{ listData[0].company_email }}</span> -->
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Penanggungjawab Sparing</strong>
                  </div>
                  <div class="col-lg-10">
                    <!-- <span>{{ listData[0].user_name }}</span> -->
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Handphone Penanggungjawab Sparing</strong>
                  </div>
                  <div class="col-lg-10">
                    <!-- <span>{{ listData[0].user_phone }}</span> -->
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Email Penanggungjawab Sparing</strong>
                  </div>
                  <div class="col-lg-10">
                    <!-- <span>{{ listData[0].user_email }}</span> -->
                  </div>
                </div>

                <hr>

                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Tahapan Pemasangan SPARING</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ listData[0].tahapan }}</span>
                  </div>
                </div>

                  <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Bukti pengadaan</strong>
                        </div>
                        <div class="col-lg-9">
                          <span><a :href="`https://${listData[0].files.tender_file_id}`" target="blank">Lihat File</a></span>
                        </div>
                      </div>
                    </li>
  
  
                    <li class="list-group-item" v-if="listData[0].tahapan == 'Pengoperasian'">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Dokumen pemasangan</strong>
                        </div>
                        <div class="col-lg-9">
                          <span><a :href="`https://${listData[0].files.pemasangan_file_id}`" target="blank">Lihat File</a></span>
                        </div>
                      </div>
                    </li>
  
                    <li class="list-group-item" v-if="listData[0].tahapan == 'Pengoperasian'">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Foto Instalasi</strong>
                        </div>
                        <div class="col-lg-9">
                          <span><a :href="`https://${listData[0].files.pic_pemasangan_file_id}`" target="blank">Lihat File</a></span>
                        </div>
                      </div>
                    </li>
  
                    <li class="list-group-item" v-if="listData[0].tahapan == 'Pengoperasian'">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Foto Sparing</strong>
                        </div>
                        <div class="col-lg-9">
                          <span><a :href="`https://${listData[0].files.pic_sparing_file_id}`" target="blank">Lihat File</a></span>
                        </div>
                      </div>
                    </li>
  
                    <li class="list-group-item" v-if="listData[0].tahapan == 'Pemasangan'">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Jadwal pemasangan</strong>
                        </div>
                        <div class="col-lg-9">
                          <span><a :href="`https://${listData[0].files.jadwal_file_id}`" target="blank">Lihat File</a></span>
                        </div>
                      </div>
                    </li>
  
                    <li class="list-group-item" v-if="listData[0].tahapan == 'Pemasangan'">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Pemenang Sparing</strong>
                        </div>
                        <div class="col-lg-9">
                          <span><a :href="`https://${listData[0].files.pemenang_file_id}`" target="blank">Lihat File</a></span>
                        </div>
                      </div>
                    </li>
  
                    <li class="list-group-item" v-if="listData[0].tahapan == 'Pengoperasian'">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Perencanaan masa uji</strong>
                        </div>
                        <div class="col-lg-9">
                          <span><a :href="`https://${listData[0].files.commission_file_id}`" target="blank">Lihat File</a></span>
                        </div>
                      </div>
                    </li>
  
                    <li class="list-group-item" v-if="listData[0].tahapan == 'Pengoperasian'">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Rencana pengoperasian uji</strong>
                        </div>
                        <div class="col-lg-9">
                          <span><a :href="`https://${listData[0].files.pengoperasian_file_id}`" target="blank">Lihat File</a></span>
                        </div>
                      </div>
                    </li>
                  </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
  <script>
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import BaseHeader from "@/components/BaseHeader";
  import ModalValidasi from "../ModalValidasi.vue";
  import dummyPelaporanProgress from "../../../Tables/dummyPelaporanProgress";
  import API from "../../../../api/base_url.js"
  
  export default {
    components: {
      BaseHeader,
      RouteBreadCrumb,
      ModalValidasi
    },
    mounted() {
      let result = dummyPelaporanProgress.filter(data => data.id == this.$route.params.id)
      this.listData = result

      this.getData()
    },
    data() {
      return {
        listData: [],
        showModal:false,
      };
    },
    methods: {
      getData() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`progress-reports/${this.$route.params.id}` , {headers})
        .then(({data:content}) => {
          this.listData = [content.data]
          this.isLoading = false
        })
        .catch((err) => {
          console.log(err);
        })
      },
      validasiHandler() {
        console.log('call');
        this.showModal = true;
      },
      onCloseModal() {
        this.showModal = false;
        this.getData();
      },
      back() {
        this.$router.push("/verifikasi/progress-report");
      },
    },
  };
  </script>
  <style></style>
  